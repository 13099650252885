/* Application-wide Styles */
h1 {
    color: #369;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 250%;
}

h2, h3 {
    color: #444;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}


body, input[type="text"], button {
    color: #333;
    font-family: Cambria, Georgia, serif;
}

/* everywhere else */
* {
    font-family: Arial, Helvetica, sans-serif;
}

.area {
    border: 2px cadetblue solid;
    margin: 5px;
    padding: 20px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
